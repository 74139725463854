import React from "react";


const IMG = ()=>

    <div className="placeholder-content">
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
        <div className="placeholder-content_item"></div>
    </div>;

export default IMG;