import React, { Component } from "react";
import { connect } from "react-redux";
// import { connect } from "react-redux";





class AboutComponent extends Component {

    render() {
        let{about,props,activeLang} = this.props;
       
        return (
            <div className="about-root" id="about" >
                {about &&  
                <div className="about-wrapper" >
                    <div className="about-container safe-area" >
                        <div className="about-text-block">

                            <div className="tex-item" >
                                <div className="big-tex-block " >
                                    <div className="page-sign">
                                        <span></span>
                                    </div>

                                    <div className="bg-text-item" >
                                        <p>{about.bigText}</p>
                                    </div>
                                    <div className="sm-text-item" >
                                        <p>{about.titleText}</p>
                                    </div>
                                    <div className="desc-text-item">
                                        <p>{about.descText}</p>
                                    </div>
                                    <div className="count-block" >
                                        {
                                            about.work&& about.work.map((item, key) =>
                                                <div className="count-text-item " >
                                                    <div className="count-number" >
                                                        <span>{item.title}</span>
                                                        <p>{item.desc}</p>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                }



            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    about: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].about?state.homeReducer[state.LangReducer.lang].about : null,
    
})
export default connect(mapStateToProps) (AboutComponent);