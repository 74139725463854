import React,{Component} from "react";
import RoutesComponent from "./component/routesComponent";


class App extends Component{
  render() {
    return(
        <div>
          <RoutesComponent/>

        </div>
    )
  }

}



export default App;
