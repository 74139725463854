
export const scrollToElement = (refEl) => {
    
    let elementPosition = getElementPosition(refEl);
    

    window.scrollTo({
        top: elementPosition.y-100,
        left: 0,
        behavior: 'smooth'
    });
};
export const scrollToTop=()=>{
    window.scrollTo({
        top:0,
        left:0,
        behavior:'smooth'
    })
}
export const getElementPosition = (el) => {
    var xPos = 0;
    var yPos = 0;
    
    while (el) {
        if (el.tagName === "BODY") {
            xPos += (el.offsetLeft + el.clientLeft);
            yPos += (el.offsetTop + el.clientTop);
        } else {
            // for all other non-BODY elements
            xPos += (el.offsetLeft - el.scrollLeft + el.clientLeft);
            yPos += (el.offsetTop - el.scrollTop + el.clientTop);
        }
        el = el.offsetParent;
    }
    return {
        x: xPos,
        y: yPos
    };
};