import { RECEIVE_HOME} from "../constants/actionTypes";
 
const initialState ={};

const homeReducer= (state = initialState, action) =>{
    switch(action.type){
        case RECEIVE_HOME:
            return{
                ...state,
                ...action.payload
            }
            default:
                return state;
    }
}


export default homeReducer;
