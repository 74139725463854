import React,{Component} from 'react';
import {Redirect, Route, Switch,withRouter} from "react-router-dom";
import * as ROUTES  from "../constants/routePaths";
import  { ENLANG , TRLANG , RUSLANG } from "../../src/constants/path";
import HomePage from "../pages/homePage";
import soonPage from "../pages/soonPages";

import Paths from "../helper/path"
import {changeActiveLangAction} from "../actions";
import {connect} from "react-redux";


class RoutesComponent extends  Component{
    
    paths = null;
    constructor(props){
        super(props);
        this.checkLang(props);


    }
    componentWillReceiveProps(nextProps) {
        if (this.props.location.pathname !== nextProps.location.pathname) {
            let locationArr = nextProps.location.pathname.split("/");
            this.pathGenerator(locationArr[1]);
            this.props.dispatch(changeActiveLangAction(locationArr[1]));
        }
    }
    pathGenerator = (lang) => {
        this.paths = new Paths(lang, ROUTES.routePaths);
    }
    checkLang = (props) => {
        let locationArr = this.props.location.pathname.split("/");
        
        if (locationArr[1] === ENLANG) {
            this.pathGenerator(ENLANG);
            this.props.dispatch(changeActiveLangAction(ENLANG));
        } else if (locationArr[1] === ENLANG) {
            this.pathGenerator(ENLANG);
            this.props.dispatch(changeActiveLangAction(ENLANG));
        }
        else if (locationArr[1] === RUSLANG) {
            this.pathGenerator(RUSLANG);
            this.props.dispatch(changeActiveLangAction(RUSLANG));
        }
        else if(locationArr[1] === "") {
            this.props.history.push("/en");
            this.pathGenerator(ENLANG);
        }else if (!locationArr[1] || locationArr[1].length === 0) {
            this.props.dispatch(changeActiveLangAction(ENLANG));
            this.props.history.push("/en");
            //this.pathGenerator(TRLANG);
        } else{
            locationArr.splice(1, 0, ENLANG);
            this.pathGenerator(ENLANG);
            this.props.history.push("/en/404");
        }
    };

    render() {
        
        return (
            <div>
                <Switch>
                    <Route exact path={this.paths.home} component={HomePage}/>
                    <Route exact path={"/tr/soon"} component={soonPage}/>
                    {/*<Route exact path={this.paths.not_found} component={NotFoundPage} />*/}
                    <Redirect from={"*"} to={this.paths.home} />
                </Switch>
            </div>
        );
    }

}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
});
export default withRouter(connect(mapStateToProps)(RoutesComponent));