
let isProd = true;
export const ASSETS = `/assets`;
export const WEB_SERVICE = isProd ?(typeof window === "undefined" ?  "https://monetapa.com/data/":"/data/") : (typeof window === "undefined" ?  "https://moneta.gulenayva.com/data/":"http://localhost:3000/data/");
export const SITE_URL = isProd == true ?"https://monetapa.com" :"https://moneta.gulenayva.com";
export const RUSLANG = "rus";
export const EN = "/en";
export const TR = "/tr";
export const RUS =  "/rus";

export const languages=[{label:"TR",value:"tr"},{label:"EN",value:"en"},{label:"RUS",value:"rus"}];