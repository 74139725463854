import React, { Component } from 'react';

class RefarenceSliderComponent extends Component {
    render() {
        return (
            <div className='ref-slider-root'>
                <div className="ref-slider-container">
                    <div className="slider-block">
                        <div className="slider-item">
                            
                        </div>
                    </div>
                </div>
            </div>
        ); 
    }
}

export default RefarenceSliderComponent;
