import {combineReducers} from 'redux';
import homeReducer from '../reducers/homeReducer';
import LangReducer from './langReducer';
import LayoutReducer from './layoutReducer';

const combineRed=combineReducers({
   homeReducer,
   LangReducer,
   LayoutReducer

});

export default combineRed
