import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { ASSETS, TR, EN, RUS } from "../../constants/paths";
import { HOME } from '../../constants/routePaths';
// import LazyLoad from 'react-lazyload';
// import IMG from "../common/lazyComponent";

class HeaderComponent extends Component {

    state = {
        activeMenuName: "",
        langActive: "",
        selectLang: "",
        langSelected: "Tr",
        
       

    }
    userActionProfile = React.createRef();

    windowClicked = (e) => {
        if(this.userActionProfile.current && !this.userActionProfile.current.contains(e.target) && this.state.langActive){
            this.setState({
                langActive: 'close'
            })
        }
    }

   

    componentDidMount(){
        window.addEventListener("click",this.windowClicked)
        // window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount(){
        // window.removeEventListener('scroll', this.handleScroll)
        
    }

   

    langActiveMenu = (name) => {
        if (name === "open") {
            this.setState({
                langActive: "open"
            })

        } else {
            this.setState({
                langActive: "close"
            })
        }


    }

    selectLang = (lang) => {
        this.setState({
            selectLang: lang,
            langSelected: lang
        })
    }

    selectedHeadMenu = (name) => {

        this.setState({
            activeMenuName: name
        })

    }

    toggleClose = (name) => {
        const menu = document.getElementById("menu-mobile");
        const check = document.getElementById("overlay-input");
        const wrapper = document.getElementById("header-wrapper")
        const logoBlack = document.getElementById("logo-mobile-item")
        const logoWhite = document.getElementById("logo-mobile-item-white")

        if (name === 'close') {
            menu.classList.remove("active")
            wrapper.style.backgroundColor = "#fff"
            // logoBlack.style.display="block"
            logoBlack.style.removeProperty("display")
            logoWhite.style.display = "none"
            check.checked = false
        }
    }

    togleMenu = () => {
        const menu = document.getElementById("menu-mobile");
        const check = document.getElementById("overlay-input");
        const wrapper = document.getElementById("header-wrapper")
        const logoBlack = document.getElementById("logo-mobile-item")
        const logoWhite = document.getElementById("logo-mobile-item-white")


        if (check.checked == true) {
            menu.classList.add("active")
            wrapper.style.backgroundColor = "#20284A"
            logoBlack.style.display = "none"
            logoWhite.style.display = "block"

        }

        else {
            menu.classList.remove("active")
            wrapper.style.backgroundColor = "#fff"
            // logoBlack.style.display="block"
            logoBlack.style.removeProperty("display")
            logoWhite.style.display = "none"
        }





    }

    render() {
        let { layoutLang, activeLang } = this.props;

        return (
            <header className="header-root" ref={this.userActionProfile}>
                {layoutLang &&
                    <div id="header-wrapper" className="header-wrapper " >
                        <div className="header-container safe-area" >
                            <div className="logo-item">
                                <Link to={HOME + (this.props.activeLang ? this.props.activeLang : "tr")}>

                                    <picture>
                                        <source media="(min-width: 768px)" srcSet={`${ASSETS}/img/header/logo.webp 1x, ${ASSETS}/img/header/logo_2x.webp 2x`} type="image/webp" />
                                        <source media="(max-width: 768px)" srcSet={`${ASSETS}/img/header/logo.png 1x, ${ASSETS}/img/header/logo_2x.png 2x`} type="image/png" />
                                        <img src={`${ASSETS}/img/header/logo.png`} alt="logo" />
                                    </picture>

                                </Link>
                            </div>
                            <div id="logo-mobile-item" className="logo-mobile-item">
                                <Link to={HOME + (this.props.activeLang ? this.props.activeLang : "tr")}>
                                    
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet={`${ASSETS}/img/header/logo-mobile.webp 1x, ${ASSETS}/img/header/logo-mobile_2x.webp 2x`} type="image/webp" />
                                        <source media="(max-width: 768px)" srcSet={`${ASSETS}/img/header/logo-mobile.png 1x, ${ASSETS}/img/header/logo-mobile_2x.png 2x`} type="image/png" />
                                        <img src={`${ASSETS}/img/header/logo-mobile.png`} alt="logo-mobile" />
                                    </picture>
                                    
                                </Link>
                            </div>
                            <div id="logo-mobile-item-white" className="logo-mobile-white-item">
                                <Link to={HOME + (this.props.activeLang ? this.props.activeLang : "tr")}>
                                    
                                    <picture>
                                        <source media="(min-width: 768px)" srcSet={`${ASSETS}/img/header/mabile-logo-white.webp 1x, ${ASSETS}/img/header/mabile-logo-white_2x.webp 2x`} />
                                        <source media="(max-width: 768px)" srcSet={`${ASSETS}/img/header/mabile-logo-white.png 1x, ${ASSETS}/img/header/mabile-logo-white_2x.png 2x`} type="image/png" />
                                        <img src={`${ASSETS}/img/header/mabile-logo-white.png`} alt="mabile-logo-white" />
                                    </picture>

                                </Link>
                            </div>

                            <div className="mobile-menu-item">
                                <div className="logo-mobile-menu  " onClick={() => { this.togleMenu() }}>
                                    <div className="open-menu-btn" >
                                        <input type="checkbox" id="overlay-input" />
                                        <label htmlFor="overlay-input" className="overlay-button">
                                            <span></span>
                                        </label>
                                    </div>

                                </div>
                                <div className="mobile-menu-block" id="menu-mobile"  >
                                    <div className="mobile-menu-item-block">
                                        <div className="mobile-item"><span onClick={() => { this.props.goToSection("about"); this.toggleClose('close') }} >{layoutLang.about}</span></div>
                                        <div className="mobile-item"> <span onClick={() => { this.props.goToSection("services"); this.toggleClose('close') }} >{layoutLang.service}</span> </div>
                                        <div className="mobile-item"> <span onClick={() => { this.props.goToSection("contact"); this.toggleClose('close') }} >{layoutLang.contact}</span> </div>


                                    </div>
                                    {/* <div className="mobile-menu-lang-block">
                                        <Link to={TR} onClick={() => { this.toggleClose('close') }} className="lang-item "> <p>Tr</p> </Link>
                                        <Link to={EN} onClick={() => { this.toggleClose('close') }} className="lang-item"> <p>En</p> </Link>
                                        <Link to={RUS} onClick={() => { this.toggleClose('close') }} className="lang-item"> <p>Rus</p> </Link>
                                    </div> */}
                                </div>
                            </div>
                            <ul className="header-menu">

                                <li id="menu-about" onClick={() => { this.props.goToSection("about"); this.selectedHeadMenu('about') }} className={`head-menu ${this.state.activeMenuName === "about" ? "active" : ""}`}  >
                                    {layoutLang.about}
                                    <div className="sp-border">

                                        <picture>
                                            <img src={`${ASSETS}/img/line-abo.png`} alt="hoverLine" />
                                        </picture>

                                    </div>

                                </li>


                                <li id="menu-service" onClick={() => { this.props.goToSection("services"); this.selectedHeadMenu('services') }} className={`head-menu ${this.state.activeMenuName === "services" ? "active" : ""}`} >
                                    {layoutLang.service}
                                    <div className="sp-border">

                                        <picture>
                                            <img src={`${ASSETS}/img/line.png`} alt="hoverLine" />
                                        </picture>

                                    </div>

                                </li>


                                <li id="menu-contact" onClick={() => { this.props.goToSection("contact"); this.selectedHeadMenu('contact') }} className={`head-menu ${this.state.activeMenuName === "contact" ? "active" : ""}`} >
                                    {layoutLang.contact}
                                    <div className="sp-border">

                                        <picture>
                                            <img src={`${ASSETS}/img/line-con.png`} alt="hoverLine" />
                                        </picture>

                                    </div>

                                </li>


                            </ul>
{/* 
                            <div className={`lang-block   ${this.state.langActive === "open" ? "opened" : ""}`}   >
                                <div className='lang-block-short' onClick={() => { this.langActiveMenu('open') }} >
                                    <ul >
                                        <li>{this.state.langSelected}</li>

                                    </ul>
                                </div>
                                <div className="lang-block-tell" onClick={() => { this.langActiveMenu('close');}} >
                                    <div className="text-block" >
                                        
                                        <Link to={EN} className={`text-item  ${this.state.selectLang === "En" ? "active" : ""}`} onClick={() => { this.langActiveMenu('close'); this.selectLang('En') }} > <span>En</span> </Link>
                                        <Link to={TR} className={`text-item ${this.state.selectLang === "Tr" ? "active" : ""}`} onClick={() => { this.langActiveMenu('close'); this.selectLang('Tr') }} > <span> Tr</span> </Link>
                                        <Link to={RUS} className={`text-item ${this.state.selectLang === "Ru" ? "active" : ""}`} onClick={() => { this.langActiveMenu('close'); this.selectLang('Ru') }}> <span>Rus</span> </Link>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                }
            </header>
        )
    }


}

const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    layoutLang: state.LayoutReducer[state.LangReducer.lang] ? state.LayoutReducer[state.LangReducer.lang].menu : null
})

export default connect(mapStateToProps)(HeaderComponent)