import React, { Component } from "react";


class soonPage extends Component {
    render() {
        return (
            <div className="soon-container">
                <div className="soon-block">
                    <div className="logo-block">
                        <picture>
                            <source media="(min-width: 768px)" srcSet="/assets/img/footer/logo-footer.webp 1x, /assets/img/footer/logo-footer_2x.webp 2x" type="image/webp" />
                            <source media="(max-width: 768px)" srcSet="/assets/img/footer/logo-footer.png 1x, /assets/img/footer/logo-footer_2x.png 2x" type="image/png" />
                            <img src="/assets/img/footer/logo-footer.png" alt="logo" />
                        </picture>
                    </div>
                    <div className="title-block">
                        <h3>We will</h3>
                        <h1>Meet You Soon! </h1>
                    </div>
                </div>
            </div>
        )
    }
}

export default soonPage;