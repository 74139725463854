import React, { Component, Fragment } from "react";
import AboutComponent from "../component/home/AboutComponent.js";
import HeaderComponent from "../component/layout/HeaderComponent.js";
import ServiceComponent from "../component/home/ServiceComponent.js";
import ReferencesComponent from "../component/home/ReferencesComponent.js";
import ContactComponent from "../component/home/ContactComponent.js";
import FooterComponent from "../component/layout/FooterComponent.js";
import BannerComponent from "../component/home/BannerComponent.js";
import {scrollToElement} from "../helper";
import PageService from "../pageService/pageService";
import {connect} from "react-redux";







class HomePage extends Component {
    aboutComponent = React.createRef();
    servicesComponent = React.createRef();
    contactComponent = React.createRef();
    pageService = null;
    constructor(props) {
        super(props);
        this.pageService = new PageService(props.location.pathname);
        this.pageService.getData(props.dispatch);
    }
    goToSection=(referenceComp)=>{
        if(referenceComp==="about"){
            scrollToElement(this.aboutComponent.current);
        }else if(referenceComp==="services"){
            scrollToElement(this.servicesComponent.current);

        }else if(referenceComp==="contact"){
            scrollToElement(this.contactComponent.current);

        }

    }
    render() {
        
        return (
            <Fragment>
                
                    <HeaderComponent goToSection={this.goToSection} />
                
                <BannerComponent/>
                
                <div className="page-section" ref={this.aboutComponent}>
                    {/* { this.home&& this.home.map((item, key) =>
                        <AboutComponent  activeLang={this.activeLang} index={key} item={item} />
                        )
                    } */}
                    <AboutComponent/>
                    
                </div>
                <div className="page-section" ref={this.servicesComponent}>
                    <ServiceComponent />
                </div>

                <ReferencesComponent />
                <div className="page-section" ref={this.contactComponent}>
                    <ContactComponent />
                </div>

                <FooterComponent goToSection={this.goToSection}/>

            </Fragment>


        );
    }


}

export default connect() (HomePage);

