import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from 'react-router-dom';
import {HOME} from "../../constants/routePaths";
import {ASSETS} from '../../constants/paths';
import LoadingComponent from "../loadingComponent";
// import LazyLoad from 'react-lazyload';
// import IMG from "../common/lazyComponent";

class BannerComponent extends Component {
   sliderContainer = React.createRef();
   dots = React.createRef();
   dotItem = document.getElementsByClassName('dotted')
   circle = React.createRef();
   itemLength = 0

   interval;
   slideInterval;
   id = null
   state = {
      slideIndex: 1,
      bannerL: 4,
      loadingValue: 0

   }


   componentDidMount() {
      // window.addEventListener("resize", this.resize)
      this.setSliderInterval()
      // setInterval(this.nextSlide, 10000)
      this.createInterval()

      // if koşulu ile kobntrol et
      // this.sliderContainer.current.addEventListener('touchstart', this.handleTouchStart, false);
      // this.sliderContainer.current.addEventListener('touchmove', this.handleTouchMove, false);
   }

//  componentDidUpdate(prevProps,prevState){
//      if(prevState.slideIndex !== this.state.slideIndex){
//         let test =setInterval(this.nextSlide,2000)
//           clearInterval(test);
//      }
//  }

//  componentWillUnmount() {
//     window.removeEventListener("resize", this.resize)
//     this.sliderContainer.current.removeEventListener('touchstart', this.handleTouchStart, false);
//     this.sliderContainer.current.removeEventListener('touchmove', this.handleTouchMove, false);
//  }

   // dotAnimation = () => {

   //     const dotAnim = document.getElementById("dot");
   //     var deg = 0
   //     clearInterval(this.id)
   //     this.id = setInterval(frame, 360)
   //     const frame = () =>{
   //         if (deg == 360) {
   //             clearInterval(this.id)
   //         }else{
   //              dotAnim.style.animation. = 360
   //         }
   //     }

   // }
   setSliderInterval=()=>{
      clearInterval(this.slideInterval)
      this.slideInterval=setInterval(this.nextSlide,20000)
   }
   createInterval = () => {
      this.setState({
         loadingValue: 0
      })
      // this.forceUpdate();
      clearInterval(this.interval)
      this.interval = setInterval(() => {
         if (this.state.loadingValue <= 100) {
            this.setState({
               loadingValue: this.state.loadingValue + 0.6
            },()=>{
               if (this.dotItem[0] && this.dotItem[0].children) {
                  Array.from(this.dotItem).forEach((item,index)=>{
                     if(index === this.state.slideIndex-1){
                        item.children[0].style.setProperty("--loading-value", this.state.loadingValue);
                     }else{
                        item.children[0].style.setProperty("--loading-value",0);
                     }
                  })
                  // this.forceUpdate();
               }
            })
         }
      }, 10000 / 100)
   }

   slideLength = () => {
      this.itemLength = this.dots.current && this.dots.current.children.length
      return this.itemLength

   }

   nextSlide = () => {
      let itemLength = this.slideLength()
      this.setSliderInterval()
      this.createInterval()
      if (this.state.slideIndex !== itemLength) {
         this.setState({
            slideIndex: this.state.slideIndex + 1
         })

      } else if (this.state.slideIndex === itemLength) {
         this.setState({
            slideIndex: 1
         })
      }
   }


   prevSlide = () => {
      let itemLength = this.slideLength()
      this.setSliderInterval()
      this.createInterval()
      if (this.state.slideIndex !== 1) {
         this.setState({
            slideIndex: this.state.slideIndex - 1
         })
      } else if (this.state.slideIndex === 1) {
         this.setState({
            slideIndex: itemLength
         })
      }
   }


   handleTouchStart = (evt) => {
      this.xDown = evt.touches[0].clientX;
      this.yDown = evt.touches[0].clientY;
   };

   handleTouchMove = (evt) => {
      if (!this.xDown || !this.yDown) {
         return;
      }
      this.xUp = evt.touches[0].clientX;
      this.yUp = evt.touches[0].clientY;

      let xDiff = this.xDown - this.xUp;
      let yDiff = this.yDown - this.yUp;
      if (Math.abs(xDiff) + Math.abs(yDiff) > 10) {
         if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
               this.nextSlide(5);

            } else {
               this.prevSlide(5);

            }
         }
         this.xDown = null;
         this.yDown = null;
      }
   };
   moveDot = (props) => {
      this.setState({
         slideIndex: props
      })
      this.createInterval()
      this.setSliderInterval()
   }

   render() {
      let {banner, props, activeLang} = this.props;
      //console.log("this.dots.current.children",this.dot.current&& this.dot.current.children&&this.dot.current.children)


      return (
          <div className="banner-root">
              {
                      (!banner) && <LoadingComponent />
                }
                {/* <LoadingComponent /> */}
             <div className="banner-wrapper">
                <div className="banner-container safe-area">

                   {banner && banner.map((obj, index) => {

                      return (
                          <div key={index} ref={this.sliderContainer}
                               className={this.state.slideIndex === index + 1 ? "banner-item active-anim" : "banner-item"}>
                             <div className="text-block ">
                                <h1>{obj.title}</h1>
                                <p>{obj.desc}</p>


                                {/* <Link to={HOME + (this.props.activeLang ? this.props.activeLang : "tr")} className="btn-item  " >
                                            <div className="btn-img"  >

                                                    <picture>
                                                        <img src={`${ASSETS}/img/button.png`} alt="btn" />
                                                    </picture>

                                            </div>
                                            <div className="btn-img hover-btn"  >

                                                    <picture>
                                                        <img src={`${ASSETS}/img/btn-hover.png`} alt="btnHower" />
                                                    </picture>

                                            </div>
                                            <div className="btn-text" >
                                                <span >
                                                    {obj.btnText}
                                                </span>
                                            </div>
                                        </Link>*/}


                             </div>
                             <div className="banner-img-block">
                                <div key={obj.id} className='slide'>

                                   <picture>
                                      <source media="(min-width: 768px)"
                                              srcSet={`${ASSETS}${obj.path.substring(0, obj.path.lastIndexOf("."))}.webp 1x, ${ASSETS}${obj.path.substring(0, obj.path.lastIndexOf("."))}_2x.webp 2x`}/>
                                      <source media="(max-width: 768px)"
                                              srcSet={`${ASSETS}${obj.path} 1x, ${ASSETS}${obj.path.substring(0, obj.path.lastIndexOf("."))}_2x${obj.path.substring(obj.path.lastIndexOf("."))} 2x`}/>
                                      <img src={`${ASSETS}${obj.path}`} alt={obj.alt}/>
                                   </picture>

                                </div>


                             </div>
                          </div>
                      )
                   })}
                   <div className="center">
                      <span className="arrow-upper" onClick={() => this.prevSlide(banner)}/>
                      <div className="dots" ref={this.dots}>
                         {
                            banner && banner.map((item, subIndex) => (
                                <div key={subIndex}
                                     onClick={() => this.moveDot(subIndex + 1)}
                                     className={`dotted ${this.state.slideIndex === subIndex + 1 ? " active" : ""}`}
                                     ref={this.dot}>
                                   <div className="circle" ref={this.circle}/>

                                </div>

                            ))
                         }

                      </div>
                      <span className="arrow-lower" onClick={() => this.nextSlide()}></span>
                   </div>


                </div>
             </div>
          </div>
      )
   }
}

const mapStateToProps = (state) => ({
   activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
   banner: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].banner ? state.homeReducer[state.LangReducer.lang].banner : null,

})
export default connect(mapStateToProps)(BannerComponent);
