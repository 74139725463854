import async from 'async';
import * as webService from '../services/webService';
import * as actionTypes from '../constants/actionTypes';

const receiveHome = (payload) => ({
    type:actionTypes.RECEIVE_HOME,
    payload
})

export const getHomeDataInit = (dispatch) => {
    return new Promise((resolve,failure)=>{
        async.series([
            (cb) => {
                webService.getHome().then((res)=>{
                    
                    if(res.data.success){
                        cb(null,res.data.success)
                    }else{
                        cb(null,false)
                    }
                })

                .catch((err)=>{
                    cb(null,false)
                })
            }

        ],(err,result) =>{
            dispatch(receiveHome(result[0]));
            resolve(result)
        }
        )
    })
}