import React, { Component } from "react";
import { connect } from "react-redux";
import {Link} from 'react-router-dom';

class ContactComponent extends Component {
    render () {
        let{contact,props,activeLang} = this.props;
        return(
            <div className="contact-root" id="contact">
                {contact &&
                        <div className="contact-wrapper" >
                            <div className="contact-container " >
                                <div className="contact-big-item">
                                    <p>{contact.bigText}</p>
                                </div>
                                
                                <div className="contact-block" >
                                    <div className="contact-item" >
                                        <div className="contact-icon" >
                                            <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M0 8.4375C0 3.78506 4.0374 0 9 0C13.9626 0 18 3.78506 18 8.4375C18 9.96277 17.5611 11.4576 16.7306 12.7603L9.7684 23.6582C9.63405 23.8686 9.39245 23.9981 9.1309 24H9.12495C8.86565 24 8.62455 23.8744 8.4877 23.6676L1.34345 12.8743C0.46445 11.5434 0 10.0094 0 8.4375ZM4.5 8.71875C4.5 6.23745 6.5187 4.21875 9 4.21875C11.4813 4.21875 13.5 6.23745 13.5 8.71875C13.5 11.1571 11.5451 13.2188 9 13.2188C6.48585 13.2188 4.5 11.1842 4.5 8.71875ZM5.99 8.71875C5.99 10.3771 7.33715 11.7288 9 11.7288C10.6892 11.7288 12.005 10.3529 12.005 8.71875C12.005 7.06455 10.6542 5.70875 9 5.70875C7.3458 5.70875 5.99 7.06455 5.99 8.71875Z" fill="url(#paint0_linear_1043:814)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1043:814" x1="9" y1="12" x2="9" y2="29.5" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#3874E8" />
                                                        <stop offset="1" stopColor="#51ABFF" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
    
                                        </div>
                                        <div className="contact-text" >
                                            <h3>{contact.contactBlock[0].title}</h3>
                                            <p>{contact.contactBlock[0].desc}</p>
                                        </div>
                                        <div className="contact-link" >
                                            <a target="_blank"  href="https://yandex.com.tr/harita/org/tamgun_ofis/192145988034/?ll=29.974234%2C40.780125&z=20.33" >{contact.contactBlock[0].link}</a>
                                        </div>
                                    </div>
                                    <div className="contact-border"></div>
                                    <div className="contact-item" >
                                        <div className="contact-icon" >
                                            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M20.3985 15.6118L23.382 18.596C24.2046 19.4187 24.2046 20.7574 23.382 21.5801L22.2971 22.6653C19.3236 25.6394 14.5115 25.6391 11.5383 22.6653L2.22457 13.3447C-0.741546 10.378 -0.741545 5.55053 2.22471 2.58369L2.52156 2.28934L8.09127 7.77715L8.79232 7.06404L3.22548 1.59138L3.31183 1.50576C4.13219 0.685222 5.47062 0.685222 6.29318 1.50791L9.27593 4.48193C10.1071 5.3132 10.0957 6.64778 9.27673 7.46686L7.92059 8.82329C7.29588 9.44813 7.29598 10.4593 7.92059 11.084L13.7987 16.9684C14.4216 17.5913 15.4356 17.5915 16.0588 16.9682L16.8307 16.1961L22.3999 21.6834L23.1009 20.9703L17.5361 15.4996C18.364 14.791 19.6152 14.8283 20.3985 15.6118Z" fill="url(#paint0_linear_1043:837)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1043:837" x1="1.5" y1="12.8932" x2="31.5018" y2="24.8913" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#3874E8" />
                                                        <stop offset="1" stopColor="#51ABFF" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div className="contact-text " >
                                            <h3>{contact.contactBlock[1].title}</h3>
                                            <p>{contact.contactBlock[1].desc}</p>
                                        </div>
                                        <div className="contact-link" >
                                            <a target="_blank" href="tel:+905323490996" >{contact.contactBlock[1].link}</a>
                                        </div>
                                    </div>
                                    <div className="contact-border"></div>
                                    <div className="contact-item" >
                                        <div className="contact-icon" >
                                            <svg width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fillRule="evenodd" clipRule="evenodd" d="M2.25002 0.892578H21.75C22.4152 0.892578 23.0139 1.18276 23.4262 1.64322L22.0392 2.84507L12.0018 11.2182L1.96429 2.84507L0.577129 1.63956C0.989296 1.18122 1.58657 0.892578 2.25002 0.892578ZM0 3.19166V16.646C0 17.887 1.00928 18.8965 2.25002 18.8965H21.75C22.9907 18.8965 24 17.887 24 16.646V3.14308C24 3.13571 24 3.12836 23.9999 3.12101L22.5017 4.37037L12.4932 12.719C12.3518 12.8368 12.1768 12.8953 12.0018 12.8953C11.8267 12.8953 11.6517 12.8367 11.5103 12.719L1.50178 4.37037L0 3.19166Z" fill="url(#paint0_linear_1043:828)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_1043:828" x1="12" y1="9.89454" x2="12" y2="23.0224" gradientUnits="userSpaceOnUse">
                                                        <stop stopColor="#3874E8" />
                                                        <stop offset="1" stopColor="#51ABFF" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>
    
                                        </div>
                                        <div className="contact-text" >
                                            <h3>{contact.contactBlock[2].title}</h3>
                                            <p>{contact.contactBlock[2].desc}</p>
                                        </div>
                                        <div className="contact-link" >
                                            <a target="_blank" href="mailto:info@monetapa.com" >{contact.contactBlock[2].link}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                 }
                    </div>
        )
    }
    
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    contact: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].contact?state.homeReducer[state.LangReducer.lang].contact : null,
    
})
export default  connect(mapStateToProps) (ContactComponent);