import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ASSETS } from "../../constants/paths";
import LazyLoad from 'react-lazyload';
import IMG from "../common/lazyComponent";

class ServiceComponent extends Component {
    render () {
        let{service,props,activeLang} = this.props;

        return(
            <div className="service-root" id="service">
                    {service&&
                        <div className="service-container safe-area"  >
                            <div className="service-block">
                                <div className="big-text-item" >
                                    <p> {service.bigText}</p>
                                </div>
                                {service.block && 
                                <Fragment>
                                <div className="service-item" >
                                    <div className="img-item">
                                        <div className="item-number right" >
                                            
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[0].img.substring(0, service.block[0].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[0].img.substring(0, service.block[0].img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[0].img} 1x, ${ASSETS}${service.block[0].img.substring(0, service.block[0].img.lastIndexOf("."))}_2x${service.block[0].img.substring(service.block[0].img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[0].img}`} alt={service.block[0].alt} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
    
                                        <div className="item-number-mobile" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[0].imgMobile.substring(0, service.block[0].imgMobile.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[0].imgMobile.substring(0, service.block[0].imgMobile.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[0].imgMobile} 1x, ${ASSETS}${service.block[0].imgMobile.substring(0, service.block[0].imgMobile.lastIndexOf("."))}_2x${service.block[0].imgMobile.substring(service.block[0].imgMobile.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[0].imgMobile}`} alt={service.block[0].altMobile} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
    
                                        <div className="service-item-img" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 769px)" srcSet={`${ASSETS}${service.block[0].numberImg.substring(0, service.block[0].numberImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[0].numberImg.substring(0, service.block[0].numberImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(min-width: 769px)" srcSet={`${ASSETS}${service.block[0].numberImg} 1x, ${ASSETS}${service.block[0].numberImg.substring(0, service.block[0].numberImg.lastIndexOf("."))}_2x${service.block[0].numberImg.substring(service.block[0].numberImg.lastIndexOf("."))} 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[0].numberMobileImg.substring(0, service.block[0].numberMobileImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[0].numberMobileImg.substring(0, service.block[0].numberMobileImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[0].numberMobileImg} 1x, ${ASSETS}${service.block[0].numberMobileImg.substring(0, service.block[0].numberMobileImg.lastIndexOf("."))}_2x${service.block[0].numberMobileImg.substring(service.block[0].numberMobileImg.lastIndexOf("."))} 2x`} />
                                           
                                            <img src={`${ASSETS}${service.block[0].numberImg}`} alt={service.block[0].altImg} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                    </div>
    
                                    <div className="service-item-text " >
                                        <h3>{service.block[0].title}</h3>
                                        <p>{service.block[0].desc}</p>
                                    </div>
                                </div>
                                <div className="service-item reverse-item" >
    
                                    <div className="service-item-text right-text" >
                                        <h3>{service.block[1].title}</h3>
                                        <p>{service.block[1].desc}</p>
                                    </div>
                                    <div className="img-item">
                                        <div className="item-number-mobile" >
                                        <LazyLoad placeholder={<IMG />}>
                                            <picture>
                                                <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[1].imgMobile.substring(0, service.block[1].imgMobile.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[1].imgMobile.substring(0, service.block[1].imgMobile.lastIndexOf("."))}_2x.webp 2x`} />
                                                <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[1].imgMobile} 1x, ${ASSETS}${service.block[1].imgMobile.substring(0, service.block[1].imgMobile.lastIndexOf("."))}_2x${service.block[1].imgMobile.substring(service.block[1].imgMobile.lastIndexOf("."))} 2x`} />
                                                <img src={`${ASSETS}${service.block[1].imgMobile}`} alt={service.block[1].altMobile} />
                                            </picture>
                                        </LazyLoad>
                                        </div>
                                        <div className="service-item-img" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[1].numberImg.substring(0, service.block[1].numberImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[1].numberImg.substring(0, service.block[1].numberImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[1].numberImg} 1x, ${ASSETS}${service.block[1].numberImg.substring(0, service.block[1].numberImg.lastIndexOf("."))}_2x${service.block[1].numberImg.substring(service.block[1].numberImg.lastIndexOf("."))} 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[1].numberMobileImg.substring(0, service.block[1].numberMobileImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[1].numberMobileImg.substring(0, service.block[1].numberMobileImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[1].numberMobileImg} 1x, ${ASSETS}${service.block[1].numberMobileImg.substring(0, service.block[1].numberMobileImg.lastIndexOf("."))}_2x${service.block[1].numberMobileImg.substring(service.block[1].numberMobileImg.lastIndexOf("."))} 2x`} />
                                           
                                            <img src={`${ASSETS}${service.block[1].numberImg}`} alt={service.block[1].altImg} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                        <div className="item-number" >
                                       <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[1].img.substring(0, service.block[1].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[1].img.substring(0, service.block[1].img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[1].img} 1x, ${ASSETS}${service.block[1].img.substring(0, service.block[1].img.lastIndexOf("."))}_2x${service.block[1].img.substring(service.block[1].img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[1].img}`} alt={service.block[1].alt} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                    </div>
    
    
    
                                </div>
                                <div className="service-item" >
                                    <div className="img-item">
                                        <div className="item-number right" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                        <   source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[2].img.substring(0, service.block[2].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[2].img.substring(0, service.block[2].img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[2].img} 1x, ${ASSETS}${service.block[2].img.substring(0, service.block[2].img.lastIndexOf("."))}_2x${service.block[2].img.substring(service.block[2].img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[2].img}`} alt={service.block[2].alt} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                        <div className="item-number-mobile" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[2].imgMobile.substring(0, service.block[2].imgMobile.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[2].imgMobile.substring(0, service.block[2].imgMobile.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[2].imgMobile} 1x, ${ASSETS}${service.block[2].imgMobile.substring(0, service.block[2].imgMobile.lastIndexOf("."))}_2x${service.block[2].imgMobile.substring(service.block[2].imgMobile.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[2].imgMobile}`} alt={service.block[2].altMobile} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                        <div className="service-item-img" >
                                            <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[2].numberImg.substring(0, service.block[2].numberImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[2].numberImg.substring(0, service.block[2].numberImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[2].numberImg} 1x, ${ASSETS}${service.block[2].numberImg.substring(0, service.block[2].numberImg.lastIndexOf("."))}_2x${service.block[2].numberImg.substring(service.block[2].numberImg.lastIndexOf("."))} 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[2].numberMobileImg.substring(0, service.block[2].numberMobileImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[2].numberMobileImg.substring(0, service.block[2].numberMobileImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[2].numberMobileImg} 1x, ${ASSETS}${service.block[2].numberMobileImg.substring(0, service.block[2].numberMobileImg.lastIndexOf("."))}_2x${service.block[2].numberMobileImg.substring(service.block[2].numberMobileImg.lastIndexOf("."))} 2x`} />
                                           
                                            <img src={`${ASSETS}${service.block[2].numberImg}`} alt={service.block[2].altImg} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                    </div>
                                    <div className="service-item-text" >
                                        <h3>{service.block[2].title}</h3>
                                        <p>{service.block[2].desc}</p>
                                    </div>
                                </div>
                                <div className="service-item reverse-item" >
    
                                    <div className="service-item-text right-text" >
                                        <h3>{service.block[3].title}</h3>
                                        <p>{service.block[3].desc}</p>
                                    </div>
                                    <div className="img-item">
                                        <div className="item-number-mobile" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[3].imgMobile.substring(0, service.block[3].imgMobile.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[3].imgMobile.substring(0, service.block[3].imgMobile.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[3].imgMobile} 1x, ${ASSETS}${service.block[3].imgMobile.substring(0, service.block[3].imgMobile.lastIndexOf("."))}_2x${service.block[3].imgMobile.substring(service.block[3].imgMobile.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[3].imgMobile}`} alt={service.block[3].altMobile} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                        <div className="service-item-img" >
                                            <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[3].numberImg.substring(0, service.block[3].numberImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[3].numberImg.substring(0, service.block[3].numberImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[3].numberImg} 1x, ${ASSETS}${service.block[3].numberImg.substring(0, service.block[3].numberImg.lastIndexOf("."))}_2x${service.block[3].numberImg.substring(service.block[3].numberImg.lastIndexOf("."))} 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[3].numberMobileImg.substring(0, service.block[3].numberMobileImg.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[3].numberMobileImg.substring(0, service.block[3].numberMobileImg.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[3].numberMobileImg} 1x, ${ASSETS}${service.block[3].numberMobileImg.substring(0, service.block[3].numberMobileImg.lastIndexOf("."))}_2x${service.block[3].numberMobileImg.substring(service.block[3].numberMobileImg.lastIndexOf("."))} 2x`} />
                                           
                                            <img src={`${ASSETS}${service.block[3].numberImg}`} alt={service.block[3].altImg} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                        <div className="item-number" >
                                        <LazyLoad placeholder={<IMG />}>
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${service.block[3].img.substring(0, service.block[3].img.lastIndexOf("."))}.webp 1x, ${ASSETS}${service.block[3].img.substring(0, service.block[3].img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${service.block[3].img} 1x, ${ASSETS}${service.block[3].img.substring(0, service.block[3].img.lastIndexOf("."))}_2x${service.block[3].img.substring(service.block[3].img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${service.block[3].img}`} alt={service.block[3].alt} />
                                        </picture>
                                        </LazyLoad>
                                        </div>
                                    </div>
    
                                </div>
                                </Fragment>
                                }
                            </div>
                        </div>
                    }
                    </div>
        )
    }
    
}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    service: state.homeReducer[state.LangReducer.lang]&&state.homeReducer[state.LangReducer.lang].service?state.homeReducer[state.LangReducer.lang].service : [],
    
})
export default connect(mapStateToProps) (ServiceComponent);