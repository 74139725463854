import * as ROUTES from '../constants/routePaths';
import * as actionTypes from '../constants/actionTypes'
import {getHomeDataInit} from './homeService';
import * as webService from '../services/webService';
import Paths from '../helper/path';
import {ENLANG, RUSLANG, TRLANG} from "../constants/path";


const receiveLayOut = (payload) => ({
    type: actionTypes.RECEIVE_LAYOUT,
    payload
});


export default class PageService{
    currentPage = ROUTES.HOME_PAGE;

    constructor(url){
        this.currentPage = url;
        this.checkLang();
    }
    checkLang = () => {
        let locationArr = this.currentPage.split("/");
        if (locationArr[1] === ENLANG) {
            this.pathGenerator(ENLANG);
        }else if(locationArr[1] === TRLANG){
            this.pathGenerator(TRLANG);
        }
        else if(locationArr[1] === RUSLANG){
            this.pathGenerator(RUSLANG);
        }else{
            this.pathGenerator(TRLANG);
        }
    };
    pathGenerator(lang) {
        this.paths = new Paths(lang, ROUTES.routePaths);
    }

    getData = async (dispatch, urlObj, params = {}) =>{
        return new Promise(async(resolve,reject)=>{
            await webService.getLayout()
                .then((res) => {
                    if (res.data.success) {
                        dispatch(receiveLayOut(res.data.success));
                    }
                });
            if(this.paths) {
                
                switch(this.currentPage){
                    case this.paths.home:
                    getHomeDataInit(dispatch)
                        .then((res)=>{
                            resolve(res);
                    });
                    break;
                    default:
                        
                        resolve(false);
                }
            }
        })
    }
}
