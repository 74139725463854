import React, { Component } from "react";
import { connect } from "react-redux";
import {ASSETS} from'../../constants/paths';
import RefarenceSliderComponent from "../refarenceSliderComponent";
// import LazyLoad from 'react-lazyload';
// import IMG from "../common/lazyComponent";
class RefarencesComponent extends Component {

    // constructor(props){
    //     super(props)
    // }
   
    // refarencesChange = () => {

    //     const scroll = document.getElementById("scroll")
    //     const scrollDot = document.getElementById("dotS");
    //     const scrollDot2 = document.getElementById("dotS2");
    //     const scrollDot3 = document.getElementById("dotS3");
    //     const scrollDot4 = document.getElementById("dotS4");
    //     const scrollDot5 = document.getElementById("dotS5");
    //     const scrollDot6 = document.getElementById("dotS6");

    //     console.log(   "sc------------>", scroll.scrollLeft);


    //     if (scroll.scrollLeft > 50) {
    //         scrollDot5.classList.add("active")
    //         scrollDot6.classList.remove("active")

    //     }if(scroll.scrollLeft > 290){
    //         scrollDot4.classList.add("active")
    //         scrollDot5.classList.remove("active")

    //     }if(scroll.scrollLeft > 650){
    //         scrollDot3.classList.add("active")
    //         scrollDot4.classList.remove("active")

    //     }if(scroll.scrollLeft > 1000){
    //         scrollDot2.classList.add("active")
    //         scrollDot3.classList.remove("active")

    //     }if(scroll.scrollLeft > 1200){
    //         scrollDot.classList.add("active")
    //         scrollDot2.classList.remove("active")

    //     } if (scroll.scrollLeft == 0) {
    //         scrollDot6.classList.add("active")

    //     }
    //     else {
    //         // scrollDot2.classList.remove("active")
    //         // scrollDot3.classList.remove("active")
    //         // scrollDot4.classList.remove("active")
    //         // scrollDot.classList.remove("active")
    //         scrollDot5.classList.remove("active")
    //         scrollDot6.classList.add("active")

    //     }

    //     // 



    // }


    render() {
        let { references, props, activeLang } = this.props;

        return (
            <div className="contact-root references" >
                {references && 
                <div className="contact-wrapper" >
                    <div className="contact-container " >
                        <div className="contact-big-item">
                            <p>{references.bigText}</p>
                        </div>
                        <div id="scroll" className="reference-block" >
                            <div className="upper-block">
                                { references.upperBlock&& references.upperBlock.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                        
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock&& references.lowerBlock.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock2&& references.lowerBlock2.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock3&& references.lowerBlock3.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock4&& references.lowerBlock4.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock5&& references.lowerBlock5.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock6&& references.lowerBlock6.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                            <div className="lower-block">
                            { references.lowerBlock7&& references.lowerBlock7.map((item, key) =>
                                <div className="ref-item">
                                    <div className="references-img-item">
                                        
                                        <picture>
                                            <source media="(min-width: 768px)" srcSet={`${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}.webp 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x.webp 2x`} />
                                            <source media="(max-width: 768px)" srcSet={`${ASSETS}${item.img} 1x, ${ASSETS}${item.img.substring(0, item.img.lastIndexOf("."))}_2x${item.img.substring(item.img.lastIndexOf("."))} 2x`} />
                                            <img src={`${ASSETS}${item.img}`} alt={item.alt} />
                                        </picture>
                                       
                                    </div>
                                    <div className="references-text-item">
                                        <p>{item.title}</p>
                                    </div>
                                </div>)
                                }
                                
                            </div>
                         
                        </div>
                        <RefarenceSliderComponent/>
                        {/* <div className="center">
                            <div id="dotS" className="dot "></div>
                            <div id="dotS2" className="dot  "></div>
                            <div id="dotS3" className="dot "></div>
                            <div id="dotS4" className="dot "></div>
                            <div id="dotS5" className="dot "></div>
                            <div id="dotS6" className="dot "></div>

                        </div> */}
                    </div>
                </div>
                }
            </div>
        )
    }



}
const mapStateToProps = (state) => ({
    activeLang: state.LangReducer.lang ? state.LangReducer.lang : null,
    references: state.homeReducer[state.LangReducer.lang] && state.homeReducer[state.LangReducer.lang].references ? state.homeReducer[state.LangReducer.lang].references : null,

})
export default connect(mapStateToProps)(RefarencesComponent);