export const HOME_PAGE = "/:lang";
export const HOME = "/";
export const LANG_TR ="tr";
export const LANG_EN = "en";
export const LANG_RUS = "rus";
export const routePaths = {
    trLang:"/tr",
    enLang:"/en",
    rusLang:"/rus",
    home: {
        routeName: "home",
        en: "/",
        tr: "/",
        rus:"/"
    }
}
  
